import styled from "styled-components";

const Section1Wrapper = styled.section`
  // padding-top: 100px;
  padding-bottom: 40px;
  background-color: #ececec;

  .contents {
    p {
      font-weight: 400;
      text-align: justify;
      span.bold {
        font-weight: 700;
        color: #000;
      }
    }
    div {
      ul {
        margin-left: 20px;
        li {
          list-style-type: unset;
        }
      }
    }
  }
  .duration {
    p {
      font-weight: 400;
      color: rgba(0, 0, 0, 0.7);
    }
  }
`;

export default Section1Wrapper;

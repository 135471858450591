import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import { ThemeProvider } from "styled-components";
import Sticky from "react-stickynode";
import { hostingTheme } from "common/src/theme/hosting";
import {
  GlobalStyle,
  ContentWrapper,
} from "../../../containers/Hosting/hosting.style";
import { ResetCSS } from "common/src/assets/css/style";
import Navbar from "../../../containers/Hosting/Navbar";

import Footer from "../../../containers/Hosting/Footer";
import { DrawerProvider } from "common/src/contexts/DrawerContext";
import { ParallaxProvider } from "react-scroll-parallax";
import SEO from "../../../components/seo";
import TopRibbon from "../../../containers/Hosting/TopRibbon";

import PropTypes from "prop-types";
import Box from "common/src/components/Box";
import Text from "common/src/components/Text";
import Heading from "common/src/components/Heading";
import Container from "common/src/components/UI/Container";
import Section1Wrapper from "./section1Wrapper";
import Section2Wrapper from "./section2Wrapper";
import ContactMongo from "../../../containers/Hosting/ContactMongo/index";
import RaghuImage from "common/src/assets/image/hosting/Team/Raghu.jpg";
import AugustinImage from "common/src/assets/image/hosting/testimonial/testimonial.jpeg";
const Webinarpage = ({
  row,
  col,
  margintop,
  title,
  subtitle,
  sec2_title,
  whitetitle,
  sec2row,
  sec2col1,
  sec2col2,
  decoration,
}) => {
  const [url, setUrl] = useState("");
  useEffect(() => {
    const loc = window.location.pathname;
    if (loc.substr(loc.length - 1) === "/") {
      setUrl(loc.substring(0, loc.length - 1));
    } else {
      setUrl(loc);
    }
  }, []);
  return (
    <ThemeProvider theme={hostingTheme}>
      <ParallaxProvider>
        {/* <SEO
          title="Webinar"
          description="Rapid Acceleration Partners provides practical AI and Intelligent RPA solutions. Extract structured data from unstructured content. Book a demo here."
        /> */}
        <Helmet>
          <title>
            On-Demand Webinar | Achieving Peak Efficiencies in Hospitality
            Accounting Processes With Hyperautomation
          </title>
          <meta
            name="description"
            content="Register for this webinar and learn how hospitality companies use Intelligent Process Automation in achieving peak efficiencies and cut down operating costs by up to 80%"
          />
        </Helmet>
        <ResetCSS />
        <GlobalStyle />
        <ContentWrapper className="HomePage">
          <TopRibbon />
          <Sticky
            top={0}
            innerZ={9999}
            activeClass="sticky-nav-active"
            releasedClass="sticky-nav-inactive"
          >
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <Section1Wrapper>
            {/*------------------------ 
                  TOPIC TO DISPLAY ON TOP 
              --------------------------*/}
            <Box {...decoration}>
              <Container>
                <Box {...margintop}>
                  <Heading
                    {...title}
                    content="On-Demand Webinar: Achieving Peak Efficiency In Hospitality Accounting With Hyperautomation "
                  />
                  <Heading
                    {...subtitle}
                    content="Learn How Hospitality Companies Use Intelligent Process Automation - Achieve Peak Efficiencies Saving Operating Costs Up To 80%"
                  />
                </Box>
              </Container>
            </Box>
            {/*------------------------ 
                  SPLIT SECTION FOR FORMS 
              --------------------------*/}

            <Container>
              <Box {...row}>
                <Box {...col}>
                  <Heading {...sec2_title} content="About The Webinar" />
                  <div className="contents">
                    <p>
                      The hospitality industry is limping back to normalcy and
                      the accounting function is critical for business
                      continuity. Given the context, back-office employees are
                      critical to business success today and growth tomorrow.
                      Yet they spend{" "}
                      <span className="bold">over 3 hours a day</span> on
                      mundane and repetitive tasks—taking time away from doing
                      valuable work.
                    </p>
                    <p>
                      What if Intelligent Digital Workers gave your current
                      Full-Time-Employees a new capacity? Automate mundane tasks
                      and make your processes error-free with Intelligent
                      Process Automation.
                    </p>
                    <p>
                      Register now to watch the on-demand webinar and explore
                      transformative ideas on achieving peak efficiency in
                      Hospitality Accounting Processes using Hyperautomation.
                    </p>
                    <p>
                      <span className="bold">Key Takeaways:</span>
                    </p>
                    <div>
                      <ul>
                        <li>
                          Get to know what the 'hype' around Hyper automation is
                          all about.{" "}
                        </li>
                        <li>
                          {" "}
                          Learn how your peers have pivoted Hyperautomation
                          strategies to maximize efficiencies and save huge on
                          operating costs.{" "}
                        </li>
                        <li>
                          Get directions on identifying quick-win use cases
                          within the hospitality accounting processes
                        </li>
                        <li>
                          Understand how to calculate and maximize ROI with
                          Intelligent Process Automation{" "}
                        </li>
                      </ul>
                    </div>
                    <div className="duration">
                      <p>
                        Duration : <span className="bold">1 hour</span>
                      </p>
                    </div>
                  </div>
                </Box>
                <Box {...col}>
                  <ContactMongo
                    buttontext="Watch On-Demand Webinar"
                    formname="Webinar"
                    formtitle="Register Now!"
                    redirect={true}
                    name={url}
                  />
                </Box>
              </Box>
            </Container>
          </Section1Wrapper>
          <Section2Wrapper>
            <Container>
              <Heading {...whitetitle} content="Meet your presenters" />
              <Box {...sec2row}>
                <Box {...sec2col1}>
                  <div className="left_content margin_right">
                    <p className="name">Raghuraman Ramamurthy </p>
                    <p className="linkedin">
                      <a
                        href="https://www.linkedin.com/in/raghuramanramamurthy/"
                        target="_blank"
                      >
                        @RaghuramanRamamurthy
                      </a>
                    </p>
                    <p className="description">
                      Parallel Entrepreneur and Business Leader with proven
                      results in delivering significant business value through
                      end-to-end digital transformation and automation. On a
                      mission to democratize Intelligent Process Automation by
                      making it easier to access and affordable and help
                      enterprises automate processes end to end.
                    </p>
                  </div>
                  <div className="image_holder">
                    <div className="overlay"></div>
                    <img
                      src={RaghuImage}
                      alt="raghu"
                      className="host_img"
                    ></img>
                  </div>
                </Box>
                <Box {...sec2col2}>
                  <div className="image_holder">
                    <div className="overlay"></div>
                    <img
                      src={AugustinImage}
                      alt="augustin"
                      className="host_img"
                    ></img>
                  </div>

                  <div className="right_content margin_left">
                    <p className="name">Augustin Gohil </p>
                    <p className="linkedin">
                      <a
                        href="https://www.linkedin.com/in/augustin-gohil/"
                        target="_blank"
                      >
                        @AugustinGohil
                      </a>
                    </p>
                    <p className="description">
                      Helping Enterprises Scale Operations Through Intelligent
                      Process Automation and digitally transformative solution.
                      Over 20 years of cross-industry experience and on a
                      mission to make intelligent process automation accessible
                      for enterprises of all sizes and empower their full-time
                      employees with intelligent digital workers.
                    </p>
                  </div>
                </Box>
              </Box>
            </Container>
          </Section2Wrapper>
          <Footer />
        </ContentWrapper>
      </ParallaxProvider>
    </ThemeProvider>
  );
};
Webinarpage.propTypes = {
  row: PropTypes.object,
};

Webinarpage.defaultProps = {
  margintop: {
    mt: "100px",
  },
  decoration: {
    backgroundColor: "#424996",
    py: "50px",
  },
  row: {
    flexBox: true,
    flexWrap: "wrap",
    alignItems: "flex-start",
    justifyContent: "center",
    position: "relative",
  },
  sec2row: {
    flexBox: true,
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  },
  sec2col1: {
    flexBox: true,
    flexWrap: "nowrap",
    width: ["100%", "100%", "100%", "48%", "48%"],
    mr: ["0", "0", "0", "2%", "2%"],
    justifyContent: [
      "flex-start",
      "flex-start",
      "flex-start",
      "flex-end",
      "flex-end",
    ],
  },
  sec2col2: {
    flexBox: true,
    flexWrap: "nowrap",
    width: ["100%", "100%", "100%", "48%", "48%"],
    ml: ["0", "0", "0", "2%", "2%"],
    justifyContent: [
      "flex-end",
      "flex-end",
      "flex-end",
      "flex-start",
      "flex-start",
    ],
  },
  col: {
    width: ["100%", "100%", "100%", "48%", "48%"],
    mr: ["0", "0", "0", "1%", "1%"],
    ml: ["0", "0", "0", "1%", "1%"],
    pt: ["10px", "10px", "75px", "75px", "75px"],
    position: "relative",
  },

  title: {
    as: "h1",
    fontSize: ["32px", "36px", "38px", "38px", "40px"],
    fontWeight: "500",
    // color: '#0f2137',
    // color: "#fff",
    color: "#fff",
    letterSpacing: "0.025em",
    mb: 0,
    lineHeight: "1.31",
    textAlign: "center",
  },
  subtitle: {
    as: "h2",
    fontSize: ["20px", "20px", "20px", "20px", "20px"],
    fontWeight: "500",
    color: "#d9d9d9",
    letterSpacing: "0.025em",
    mt: "20px",
    mb: 0,
    lineHeight: "1.31",
    textAlign: "center",
  },
  sec2_title: {
    as: "h3",
    fontSize: ["20px", "24px", "24px", "26px", "26px"],
    fontWeight: "700",
    color: "#202342",
    letterSpacing: "0.025em",
    mb: 0,
    lineHeight: "1.31",
    textAlign: "left",
  },
  whitetitle: {
    as: "h3",
    fontSize: ["26px", "28px", "30px", "30px", "30px"],
    fontWeight: "500",
    color: "#fff",
    letterSpacing: "0.025em",
    mb: "60px",
    lineHeight: "1.31",
    textAlign: "center",
  },
};
export default Webinarpage;
